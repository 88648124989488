import PropTypes from "prop-types";
import { useMemo } from "react";
import shallow from "zustand/shallow";
import { useUserStore } from "../store";
// material
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme, StyledEngineProvider, alpha } from "@mui/material/styles";
//
import typography from "./typography";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS

export default function ThemeConfig({ children }) {
  const colours = useUserStore((state) => state.colours, shallow);

  const GREY = {
    0: "#FFFFFF",
    100: "#fafaf8",
    200: "#F4F6F8",
    300: "#DFE3E8",
    400: "#C4CDD5",
    500: "#919EAB",
    600: "#637381",
    700: "#454F5B",
    800: "#212B36",
    900: "#161C24",
    500_8: alpha("#919EAB", 0.08),
    500_12: alpha("#919EAB", 0.12),
    500_16: alpha("#919EAB", 0.16),
    500_24: alpha("#919EAB", 0.24),
    500_32: alpha("#919EAB", 0.32),
    500_48: alpha("#919EAB", 0.48),
    500_56: alpha("#919EAB", 0.56),
    500_80: alpha("#919EAB", 0.8),
  };

  const PRIMARY = (prim, sidebar, hover) => ({
    lighter: sidebar,
    light: prim,
    main: prim,
    dark: "#0A194E",
    darker: "#0A194E",
    contrastText: "#fff",
    hover: hover,
  });
  const SECONDARY = (sec) => ({
    lighter: "#D6E4FF",
    light: "#84A9FF",
    main: sec,
    dark: sec,
    darker: "#091A7A",
    contrastText: "#fff",
  });
  const INFO = {
    lighter: "#D0F2FF",
    light: "#74CAFF",
    main: "#1890FF",
    dark: "#0C53B7",
    darker: "#04297A",
    contrastText: "#fff",
  };
  const SUCCESS = {
    lighter: "#E9FCD4",
    light: "#AAF27F",
    main: "#54D62C",
    dark: "#229A16",
    darker: "#08660D",
    contrastText: GREY[800],
  };
  const WARNING = {
    lighter: "#FFF7CD",
    light: "#FFE16A",
    main: "#FFC107",
    dark: "#B78103",
    darker: "#7A4F01",
    contrastText: GREY[800],
  };
  const ERROR = {
    lighter: "#FFE7D9",
    light: "#FFA48D",
    main: "#FF4842",
    dark: "#B72136",
    darker: "#7A0C2E",
    contrastText: "#fff",
  };

  const GRADIENTS = {
    primary: createGradient(PRIMARY.light, PRIMARY.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main),
  };

  const CHART_COLORS = {
    violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
    blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
    green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
    yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
    red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
  };

  const palette = (prim = "#FFBA33", sec = "#0A194E", hover = "#ffc31f", text = "#000", sidebar = "#FFFFFF") => ({
    common: { black: "#000", white: "#fff" },
    primary: PRIMARY(prim, sidebar, hover),
    secondary: SECONDARY(sec),
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    grey: GREY,
    gradients: GRADIENTS,
    chart: CHART_COLORS,
    divider: GREY[500_24],
    text: { primary: text, secondary: GREY[600], disabled: GREY[500] },
    background: { paper: "#fff", default: GREY[100], neutral: GREY[200] },
    action: {
      active: GREY[600],
      hover: hover,
      selected: GREY[500_16],
      disabled: GREY[500_80],
      disabledBackground: GREY[500_24],
      focus: hover,
      hoverOpacity: 0.08,
      disabledOpacity: 0.48,
    },
  });

  const themeOptions = useMemo(
    () => ({
      palette: palette(
        colours?.primary || "#FFBA33",
        colours?.secondary || "#0A194E",
        colours?.hover || "#ffc31f",
        colours?.text || '#000"',
        colours?.sidebar || '"#FFFFFF"'
      ),
      shape: { borderRadius: 8 },
      typography,
      shadows,
      customShadows,
    }),
    [colours?.primary, colours?.secondary, colours?.hover, colours?.text, colours?.sidebar]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
