import { useState } from "react";
// material
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

import { Card } from "@mui/material";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { bgcolor } from "@mui/system";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({}));

// ----------------------------------------------------------------------

export default function DashboardLayout({ children, type, user, currentOrg, logout, landing, isDesktop }) {
  const [open, setOpen] = useState(false);
  const [hideSidenav, setHideSidenav] = useState(false);
  return (
    <RootStyle>
      {!isDesktop && (
        <Card
          sx={{
            borderRadius: 50,
            cursor: "pointer",
            position: "absolute",
            zIndex: 9999,
            left: !open ? 0 : 145,
            bgcolor: !open ? "rgba(255, 255, 255, 0.057)" : "rgba(255, 255, 255, 0.3)",
          }}
          onClick={() => setOpen(!open)}
        >
          <CompareArrowsIcon sx={{ height: 15 }} />
        </Card>
      )}
      {isDesktop && (
        <Card
          sx={{
            borderRadius: 50,
            cursor: "pointer",
            position: "fixed",
            zIndex: 99999,
            left: hideSidenav ? 0 : 145,
            bgcolor: hideSidenav ? "rgba(255, 255, 255, 0.057)" : "rgba(255, 255, 255, 0.3)",
          }}
          onClick={() => setHideSidenav(!hideSidenav)}
        >
          <CompareArrowsIcon sx={{ height: 15 }} />
        </Card>
      )}
      {/* <DashboardNavbar /> */}
      {!hideSidenav && (
        <DashboardSidebar
          type={type}
          logout={logout}
          user={user}
          currentOrg={currentOrg}
          landing={landing}
          setOpen={setOpen}
          isDesktop={isDesktop}
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
      )}
      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
}
