import uuid from "uuid-random";
import { requestHandler } from "./requestHandler";
import { serverUrl } from "./urlConfig";
import { User, Board, CreateVal, List, Task, Activity } from "../components/models";
import { CurrentListId } from "../pages/board/Board";

type Current = {
  board: Board;
  setBoard: (board: Board) => void;
  list: CurrentListId;
  setList: (list: CurrentListId) => void;
};

type UserData = {
  user_name?: string;
  color: string;
  name?: string;
  id: string;
  email: string;
};

export const onAssignUserToBoard = (
  user: { user_name?: string; color?: string; name?: string; email?: string },
  board: Board,
  callback: Function,
  message: string
) => {
  const assigned_users = board.assigned_users;
  assigned_users.push({ name: user.user_name, color: user.color });
  requestHandler({
    type: "put",
    route: "board/assignuser",
    body: {
      id: board.id,
      assigned_users: JSON.stringify(assigned_users),
      name: user.user_name,
      email: user.email,
      message,
    },
  }).then((response: any) => {
    if (response === "user assigned successfully") {
      callback();
    } else {
      assigned_users.pop();
      alert(response?.errors ? response.errors : "no data found");
    }
  });
};

export const onCreateBoard = (createValue: CreateVal, user: User, callback: Function, makeError: Function) => {
  requestHandler({
    route: "board/create",
    type: "post",
    body: {
      name: createValue.name,
      image: createValue.image,
      assigned_users: JSON.stringify([{ name: user.name, color: user.color }]),
    },
  }).then((data) => {
    if (data === "board created successfully") {
      callback();
    } else {
      makeError(data);
    }
  });
};

export const onAddtask = (
  currentList: CurrentListId,
  user: User,
  todo: string,
  currentBoard: Board,
  createValue: CreateVal,
  callback: Function,
  makeError: Function,
  dates: any
) => {
  const id = uuid();
  let dateMessage = null;
  if (dates)
    dateMessage = ` and changed the task date to ${new Date(dates.start_date).toLocaleDateString()} - ${new Date(
      dates.due_date
    ).toLocaleDateString()}`;
  let currentTasks = currentList.data[0]?.tasks ? currentList.data[0].tasks : [];
  currentTasks.push({
    id,
    name: todo,
    assigned_users: [{ id: 1, name: user.name, color: user.color }],
    board_name: currentBoard.name,
    due_date: dates?.due_date ? dates.due_date : null,
  });
  requestHandler({
    type: "post",
    route: "task/create",
    body: {
      name: todo,
      list_id: currentList.data[0].id,
      list: currentList.data[0].name,
      id: id,
      tasks: JSON.stringify(currentTasks),
      board_name: currentBoard.name,
      start_date: dates?.start_date ? dates.start_date : null,
      due_date: dates?.due_date ? dates.due_date : null,
      color: createValue.color || null,
      created_by: user.name,
      assigned_users: JSON.stringify([{ name: user.name, color: user.color }]),
      updateList: !currentList.has_tasks ? true : false,
      task_activity: JSON.stringify([
        {
          message: ` created this task on ${currentList.data[0].name} ${dateMessage && dateMessage} `,
          name: user.name,
          color: user.color,
          date: new Date().toLocaleString(),
          sortDate: new Date().getTime(),
        },
      ]),
    },
  }).then((res) => {
    if (res === "task created successfully") {
      callback();
    } else {
      makeError(res);
      currentTasks.pop();
    }
  });
};

export const onCreateList = (listData: List[], createValue: CreateVal, current: Current, callback: Function) => {
  const id = uuid();
  let currentListsData = listData ? listData : [];
  currentListsData.push({ id, name: createValue.name });
  requestHandler({
    type: "post",
    route: "list/create",
    body: {
      board_id: current.board.id,
      name: createValue.name,
      lists: JSON.stringify(currentListsData),
      image: createValue?.color && createValue.color,
      id,
      board_name: current.board.name,
    },
  }).then((response) => {
    if (response === "list created successfully") {
      callback();
    } else {
      currentListsData.pop();
      alert(response?.errors ? response.errors : "no data found");
    }
  });
};

export const onAssignUserToTask = (
  taskData: Task,
  userData: UserData,
  users: User[],
  user: User,
  task_activity: Activity[],
  currentTaskData: Task[],
  date: string,
  callback: Function,
  unassign = false
) => {
  requestHandler({
    type: "put",
    route: "task/updatedata",
    body: {
      id: taskData.id,
      assigned_users: JSON.stringify(users),
      name: userData.user_name || userData.name,
      email: userData.email,
      tasks: "1",
      task_activity: JSON.stringify(task_activity),
      unassign,
      message: `You have been ${unassign ? "unassigned" : "assigned"} to task ${taskData.name} by ${
        user.name === userData.user_name ? "yourself" : user.name
      } at ${date},
      
      
      ${window.location.href}

      Thanks for using Task Manager,
      `,
    },
  }).then((response) => {
    if (response === "task updated successfully") {
      requestHandler({
        type: "put",
        route: "task/update",
        body: {
          id: taskData.id,
          list_id: taskData.list_id,
          prev_listid: taskData.list_id,
          tasks: JSON.stringify(currentTaskData),
        },
      }).then((res) => {
        if (res === "task updated successfully") {
          callback();
        }
      });
    } else {
      users.pop();
      alert(response?.errors ? response.errors : "no data found");
    }
  });
};

export const onUpdateTask = (taskData: Task, currentTaskData: Task[], tasks: Task[], callback: Function, setLoading: Function) => {
  requestHandler({
    type: "put",
    route: "task/update",
    body: {
      id: taskData.id,
      list_id: taskData.list_id,
      prev_listid: taskData.list_id,
      tasks: JSON.stringify(currentTaskData),
    },
  }).then((response) => {
    if (response === "task updated successfully") {
      requestHandler({
        type: "put",
        route: "task/updatedata",
        body: { tasks },
      }).then((res) => {
        if (res === "task updated successfully") {
          setLoading(false);
          callback();
        } else {
          if (taskData?.status) {
            taskData.task_activity.pop();
          }
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  });
};

export const onSuspendRestore = (taskData: Task, task_activity: Activity[], archive: boolean, callback: Function) => {
  requestHandler({
    route: "task/suspendrestore",
    type: "post",
    body: {
      id: taskData.id,
      type: archive ? "restore" : "suspend",
      task_activity: JSON.stringify(task_activity),
      list_id: taskData.list_id,
      name: taskData.name,
      color: taskData.color,
      assigned_users: taskData.assigned_users,
      due_date: taskData.due_date,
      labels: taskData.labels,
    },
  }).then((res) => {
    const msg = archive ? "task restored successfully" : "task suspended successfully";
    if (res === msg) {
      callback();
    } else {
      alert(res?.errors ? res.errors : "error updating task");
      task_activity.pop();
    }
  });
};

export const onAddTaskItem = (body: any, callback: Function, message: string, taskActivity: Activity[]) => {
  requestHandler({
    type: "post",
    route: "taskitem/add",
    body,
  }).then((res) => {
    if (res === message) {
      callback();
    } else {
      alert(res?.errors ? res.errors : "error adding task item");
      taskActivity.pop();
    }
  });
};

export const onUpdateActivity = (taskActivity: Activity[], id: string, callback: Function) => {
  requestHandler({
    type: "put",
    route: "task/activity",
    body: {
      id,
      task_activity: JSON.stringify(taskActivity),
    },
  }).then((res) => {
    if (res === "task updated successfully") {
      callback();
    } else {
      alert(res?.errors ? res.errors : "error updating task activity");
      taskActivity.pop();
    }
  });
};

export const onDeleteTask = (id: string, callback: Function) => {
  requestHandler({ type: "delete", route: "task/delete", body: { id } }).then((res) => {
    if (res === "task deleted successfully") {
      callback();
    } else {
      alert(res?.errors ? res.errors : "error deleting task");
    }
  });
};

export const upload = async (
  data: Blob,
  taskData: Task,
  user: User,
  callback: any,
  loading = (b: boolean) => {},
  makeError = Function,
  lrs = false,
  qms = false
) => {
  try {
    const form = new FormData();
    form.append("data", data);
    form.append("task_id", taskData.id);
    form.append("board", taskData.board_name);
    form.append("name", user.name);
    if (lrs) {
      form.append("lrs", "true");
    } else if (qms) {
      form.append("qms", "true");
    }
    const req = await fetch(serverUrl + "file/save", {
      method: "POST",
      body: form,
      credentials: "include",
    });
    const res = await req.json();
    if (res?.id) {
      callback(res);
    } else {
      makeError(res);
    }
    loading(false);
  } catch (e) {
    console.log(e);
    loading(false);
  }
};

export const onDeleteFiles = (id: string, taskExists: boolean, taskId: string, callback: Function, type: any, files: any) => {
  requestHandler({
    type: "post",
    route: "file/delete",
    body: { id, taskExists, taskId, type, files: JSON.stringify(files) },
  }).then((res) => {
    if (res === "file deleted successfully") {
      console.log(res);
      callback(taskExists);
    } else {
      console.log(res?.errors ? res.errors : "error deleting file");
    }
  });
};

export const onLogout = (logout: Function, makeError: Function) => {
  requestHandler({ route: "auth/logout", type: "post" }).then((data) => {
    if (data === "logged out successfully") {
      logout();
    } else {
      makeError(data);
    }
  });
};

export const onAddToOrg = (profile_id: string, admin = false, callback: Function) => {
  requestHandler({
    route: "org/adduser",
    type: "post",
    body: { profile_id, admin, link: window.location.href },
  }).then((res) => {
    if (res === "user added successfully") {
      callback();
    } else {
      alert(res?.errors ? res.errors : "error adding to org");
    }
  });
};

export const onMoveTask = (
  id: string,
  nextId: string,
  prevId: string,
  board_name: string,
  tasks: Task[],
  prev_tasks: Task[],
  user: User,
  callback: Function,
  error: Function,
  prevName = null,
  nextName = null
) => {
  requestHandler({
    type: "put",
    route: "task/update",
    body: {
      id: id,
      list_id: nextId,
      prev_listid: prevId,
      board_name: board_name,
      prev_listname: prevName ? prevName : "",
      next_listname: nextName ? nextName : "",
      users: tasks.find((task) => task.id === id)?.assigned_users,
      tasks: JSON.stringify(tasks),
      prev_tasks: JSON.stringify(prev_tasks),
      name: user.name,
    },
  }).then((response) => {
    if (response !== "task updated successfully") {
      error(response);
    } else {
      callback();
    }
  });
};
