import create from "zustand";
import { devtools, persist } from "zustand/middleware";

let userStore = (set) => ({
  user: null,
  logo: "https://images.pexels.com/photos/572897/pexels-photo-572897.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  setLogo: (data) => set({ logo: data }),
  setUserData: (data) => set({ user: data }),
  logout: () =>
    set({
      user: null,
      currentRoom: null,
      role: "",
      currentOrg: null,
      colours: {
        primary: "#FFBA33",
        secondary: "#040560",
        hover: "#ffc31f",
        text: "#272626",
        sidebar: "#FFFFFF",
      },
    }),
  logoutOrg: () =>
    set({
      currentOrg: null,
      currentRoom: null,
      role: "",
      logo: null,
      colours: {
        primary: "#FFBA33",
        secondary: "#040560",
        hover: "#ffc31f",
        text: "#272626",
        sidebar: "#FFFFFF",
      },
    }),
  currentOrg: null,
  setCurrentOrg: (data) => set({ currentOrg: data }),
  colours: {
    primary: "#FFBA33",
    secondary: "#040560",
    hover: "#ffc31f",
    text: "#272626",
    sidebar: "#FFFFFF",
  },
  setColours: (data) => set({ colours: data }),
  colorMode: "light",
  setColorMode: (color) => set({ colorMode: color }),
  role: "",
  setRole: (data) => set({ role: data }),
  currentRoom: null,
  setCurrentRoom: (data) => set({ currentRoom: data }),
});

let requestStore = (set) => ({
  // setCurrentCacheData: (data, key) => set((state) => ({ [key]: data })),
  socketData: null,
  setSocketData: (data) => set({ socketData: data }),
  setCurrentCacheData: (data, key) => set({ [key]: data }),
});

let taskStore = (set) => ({
  taskModal: false,
  currentBoardName: null,
  setCurrentBoardName: (data) => set({ currentBoardName: data }),
  setTaskModal: (data) => set({ taskModal: data }),
});

userStore = devtools(userStore);
userStore = persist(userStore, { name: "user_data" });
requestStore = devtools(requestStore);
taskStore = devtools(taskStore);

export const useUserStore = create(userStore);
export const useRequestStore = create(requestStore);
export const useTaskStore = create(taskStore);
