import React from "react";
import { Tooltip, Avatar, Popover, Card, Typography, Box, Divider, Button, AvatarGroup } from "@mui/material";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { User } from "../models";

type Props = {
  users: { name?: string; color?: string; id?: string; user_name?: string }[];
  size?: number;
  unassign?: Function | null;
  logout?: Function | null;
  logoutOrg?: Function | null;
  setChildHover?: Function | null;
  org?: boolean;
  cascade?: boolean;
};
function AvatarGroups({
  users,
  size = 25,
  unassign = null,
  logout = null,
  logoutOrg = null,
  org = false,
  setChildHover = () => {},
  cascade = false,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState<User | null>(null);
  const styles = makeStyles(size);

  const handleClick = (event, user) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentUser(user);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      {cascade ? (
        // @ts-ignore
        <AvatarGroup size={size}>
          {users.map((user, i) => (
            <Avatars user={user} styles={styles} i={i} org={org} handleClick={handleClick} key={i} />
          ))}
        </AvatarGroup>
      ) : (
        users?.map((user, i: React.Key) => <Avatars user={user} styles={styles} i={i} org={org} handleClick={handleClick} key={i} />)
      )}

      <Dropdown
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        setChildHover={setChildHover}
        currentUser={currentUser}
        unassign={unassign}
        logout={logout}
        logoutOrg={logoutOrg}
      />
    </>
  );
}

export const Avatars = ({ org, user, handleClick, styles, i }) => {
  return (
    <Tooltip title={org ? user?.user_name : user?.name || user?.user_name} placement="bottom" key={i}>
      <Avatar onClick={(e) => handleClick(e, user)} sx={styles.avatar(user)}>
        {org
          ? user?.user_name[0].toUpperCase()
          : (user?.name && user?.name[0].toUpperCase()) || (user?.user_name && user?.user_name[0].toUpperCase())}
      </Avatar>
    </Tooltip>
  );
};

export const Dropdown = ({ anchorEl, setAnchorEl, open, currentUser, unassign, logout, logoutOrg, setChildHover }) => {
  const styles = dropDownStyles();
  return (
    <Popover
      sx={{ mt: 4 }}
      anchorEl={anchorEl}
      onClose={() => {
        setAnchorEl(null);
        setChildHover(false);
      }}
      open={open}
      // PaperProps={{
      //   sx: {
      //     mt: 1.5,
      //     ml: 0.5,
      //     overflow: "inherit",
      //     // boxShadow: (theme) => theme.customShadows.z20,
      //     border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`,
      //     width: 200,
      //   },
      // }}
    >
      <Card sx={styles.cont}>
        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <Avatar sx={styles.bigAvatar(currentUser?.color)}>{currentUser?.name[0].toUpperCase() || currentUser?.user_name[0].toUpperCase()}</Avatar>
          <Typography sx={styles.name}>{currentUser?.name || currentUser?.user_name}</Typography>
        </Box>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Box sx={{ flexDirection: "column", display: "flex" }}>
          {unassign && (
            <Button onClick={() => unassign(currentUser, true)} sx={styles.button}>
              Unassign
            </Button>
          )}
          {logoutOrg && (
            <Button sx={{ color: "black", position: "relative", right: 15 }} startIcon={<CorporateFareIcon color="primary" />} onClick={logoutOrg}>
              Logout from org
            </Button>
          )}
          {logout && (
            <Button sx={{ m: 2 }} variant="outlined" onClick={logout}>
              Logout
            </Button>
          )}
        </Box>
      </Card>
    </Popover>
  );
};

const makeStyles = (size: number) => ({
  avatar(user) {
    return {
      width: size,
      height: size,
      mr: 0.16,
      bgcolor: user.color,
      fontSize: 15,
      mb: 0.5,
      cursor: "pointer",
    };
  },
});

const dropDownStyles = () => ({
  cont: {
    width: 200,
  },
  bigAvatar(color: string) {
    return {
      ml: 2,
      mt: 2,
      bgcolor: color,
    };
  },
  name: {
    mt: 3,
    ml: 1,
  },
  button: {
    textTransform: "none",
  },
});

export default AvatarGroups;
