import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { styled } from "@mui/material/styles";
import { Box, Drawer, Typography, SwipeableDrawer } from "@mui/material";
// hooks
// components
import Logo from "../../components/Logo";
import AvatarGroup from "../../components/avatarGroup/AvatarGroup";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import { useTaskStore } from "../../store";
import sidebarConfig from "./SidebarConfig";
import { onLogout } from "../../helpers/requests";
import { onError } from "helpers/utils";

// ----------------------------------------------------------------------

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.primary.main,
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, type = "iot", isDesktop, user, currentOrg, logout, landing }) {
  const currentBoardName = useTaskStore((state) => state.currentBoardName);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");

  const { enqueueSnackbar } = useSnackbar();
  const [isHovering, setIsHovering] = useState(false);
  const [DRAWER_WIDTH, setDrawerWidth] = useState(170);

  // React.useEffect(() => {
  //   if (isHovering) {
  //     setDrawerWidth(220);
  //   } else {
  //     setDrawerWidth(64);
  //   }
  // }, [isHovering]);
  const RootStyle = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
      flexShrink: 0,
      width: DRAWER_WIDTH,
    },
  }));

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const setView = (value) => {
    if (landing || !currentBoardName) {
      navigate(`/board/${currentOrg}?view=${value}`);
    } else {
      navigate(`/board/${currentOrg}${currentBoardName && "/" + currentBoardName}?view=${value}`);
    }
  };

  const makeError = (data) => {
    enqueueSnackbar(onError(data), { variant: "error", autoHideDuration: 6000 });
  };

  const logoutUser = () => {
    onLogout(logout, makeError);
  };

  const renderContent = (
    <Scrollbar
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        height: 1,
        position: "absolute",
        background: "rgba(255, 255, 255, 0.057)",
        backdropFilter: "blur(100px)",
        boxShadow: "blur(10px) 0px 0px 10px rgba(0, 0, 0, 0.5)",
        zIndex: 1,
        "& .simplebar-content": { height: 1, display: "flex", flexDirection: "column" },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <AccountStyle>
          <AvatarGroup users={[user]} size={36} logout={logoutUser} />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
              New user
            </Typography>
            <Typography variant="body2">{currentOrg}</Typography>
          </Box>
        </AccountStyle>
      </Box>

      <NavSection type={type} navConfig={sidebarConfig[currentOrg ? type : "noOrg"]} setView={setView} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ pt: 5, borderRadius: 2, position: 'relative' }}
        >
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button
            href="https://material-ui.com/store/items/minimal-dashboard/"
            target="_blank"
            variant="contained"
          >
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <SwipeableDrawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          BackdropProps={{ invisible: true }}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "secondary.main",
              backdropFilter: "blur(100px)",
              boxShadow: "blur(10px) 0px 0px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          {renderContent}
        </SwipeableDrawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "secondary.main",
              opacity: 0.9,
              borderRightStyle: "dashed",
              backdropFilter: "blur(100px)",
              boxShadow: "blur(10px) 0px 0px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
