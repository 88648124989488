import { Notifications } from "../components/notification/Notifications";
import { User, Task, Activity } from "../components/models";

export const urlRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const onPushActivity = (
  activity: Activity[],
  date: string | Date,
  message: string,
  receiver: string | null = null,
  file: string | null = null,
  user: User
) => {
  activity.push({
    id: Date.now(),
    message: message,
    name: user?.name,
    //@ts-ignore
    file: file,
    color: user?.color,
    sortDate: new Date().getTime(),
    date: date,
    receiver: receiver,
  });
};

export const onPushCurrentTask = (currentTasks: Task[], taskData: Task, currentTaskData: Task[], assignedUsers: any) => {
  currentTasks.map((task: Task) => {
    if (task.id === taskData.id) {
      currentTaskData.push({
        id: taskData.id,
        name: taskData.name,
        //@ts-ignore
        priority: taskData.priority,
        //@ts-ignore
        comments: taskData.comments ? taskData.comments.length : null,
        //@ts-ignore
        files: taskData.files === 0 ? null : taskData.files,
        //@ts-ignore
        assigned_users: assignedUsers,
        color: taskData.color,
        due_date: taskData.due_date,
        tags: taskData.labels,
      });
    } else {
      currentTaskData.push(task);
    }
  });
};

export const makeError = (data: any, enqueueSnackbar: Function) => {
  enqueueSnackbar(onError(data), { variant: "error", autoHideDuration: 6000 });
};

export const onError = (data: { errors: string; type: string }) => {
  return data?.errors ? data.errors : "There was an error";
};

export async function downloadFile(src: string, name: string) {
  const image = await fetch(src);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement("a");
  link.href = imageURL;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
