import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useUserStore } from "./store";
import { Button } from "@mui/material";
import Lottie from "react-lottie-player";
import { SnackbarProvider } from "notistack";
import shallow from "zustand/shallow";
import NotFound from "./pages/Page404";
import DashboardLayout from "./layouts/dashboard";
import rocket from "./assets/rocket.json";
import useResponsive from "./hooks/useResponsive";
import { onLogout } from "./helpers/requests";
import { requestHandler } from "./helpers/requestHandler";
import uuid from "uuid-random";

// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import "./App.css";

//tasker
const Board = React.lazy(() => import("./pages/board/Board"));
const AuthenticationMain = React.lazy(() => import("./pages/authentication/AuthenticationMain"));
const Landing = React.lazy(() => import("./pages/landing/Landing"));
const Organization = React.lazy(() => import("./pages/organization/Organization"));
const IncasLanding = React.lazy(() => import("./pages/incasLanding/IncasLanding"));

//qms +lrs
const Qms = React.lazy(() => import("./pages/qms/Qms"));
const LrsDash = React.lazy(() => import("./pages/lrs/Lrs"));
const LrsHome = React.lazy(() => import("./pages/lrsHome/LrsHome"));

//iot
const DashboardApp = React.lazy(() => import("./pages/iot/DashboardApp"));
const User = React.lazy(() => import("./pages/User"));
const Products = React.lazy(() => import("./pages/Products"));

//auth
const Reset = React.lazy(() => import("./pages/reset/Reset"));

//chat
const RoomModal = React.lazy(() => import("./components/roomModal/RoomModal"));
const Room = React.lazy(() => import("./pages/room/Room"));

const error = (data) => {
  console.log(data);
};
// ----------------------------------------------------------------------

export default function App() {
  const [user, currentOrg, logout, currentRoom] = useUserStore((state) => [state.user, state.currentOrg, state.logout, state.currentRoom], shallow);
  const [time, setTime] = useState({ schedule: 0, user: 0 });

  const [rerender, setRerender] = useState(false);
  const isDesktop = useResponsive("up", "lg");
  const notistackRef = React.useRef(null);
  let id;

  useEffect(() => {
    if (user) {
      let myInterval = setInterval(() => {
        setTime((time) => ({ schedule: time.schedule + 1, user: time.user + 1 }));
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [user, currentOrg]);
  // for auto time tracking
  useEffect(() => {
    if (user && currentOrg) {
      id = uuid();
      const startDate = new Date();
      const filterDate = startDate.toLocaleString().split("/");
      requestHandler({
        type: "post",
        route: "activity/add",
        body: {
          type: "track",
          name: "Tracked time",
          startDate,
          id,
          year: filterDate[2].slice(0, 4),
          month: filterDate[1],
          user_name: user.name,
        },
      });
    }
  }, [user, currentOrg, rerender]);

  useEffect(() => {
    if (user) {
      if (time.schedule > 1500) {
        // update user activity if time is more than 15 minutes
        requestHandler({
          type: "post",
          route: "activity/update",
          body: { id, endDate: new Date(), time: time },
        });
        setTime({ ...time, schedule: 0 });
        setRerender(Date.now());
      }
    }
  }, [user, time]);

  useEffect(() => {
    if (user) {
      window.addEventListener("beforeunload", () => {
        requestHandler({
          type: "put",
          route: "activity/update",
          body: { endDate: new Date(), id },
        });
      });
    }
    return () => {
      window.removeEventListener("beforeunload", () => {
        return;
      });
    };
  }, [user]);

  //internet event listener

  //wrap sidebar on page
  const wrappedPage = (page, type, landing = false) => {
    return (
      <DashboardLayout user={user} type={type} currentOrg={currentOrg} logout={logout} landing={landing} isDesktop={isDesktop}>
        {page}
      </DashboardLayout>
    );
  };

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <SnackbarProvider
        maxSnack={5}
        ref={notistackRef}
        action={(key) => (
          <Button onClick={() => notistackRef?.current?.closeSnackbar(key)} style={{ color: "#fff", fontSize: "20px" }}>
            ✖
          </Button>
        )}
      >
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Lottie style={{ width: "125px", height: "124px", marginTop: "20%" }} loop animationData={rocket} play />
            </div>
          }
        >
          {user ? (
            <>
              <Routes>
                <Route path="/" element={<IncasLanding user={user} currentOrg={currentOrg}/>}/>
                <Route path="/tasker" element={wrappedPage(<Landing user={user} currentOrg={currentOrg} />, "tasker", true)} />
                <Route path="/trello" element={(wrappedPage(<Landing user={user} currentOrg={currentOrg} />, "tasker"), true)} />

                <Route
                  path="/org"
                  element={wrappedPage(<Organization user={user} isDesktop={isDesktop} currentOrg={currentOrg} />, "tasker", true)}
                />
                <Route path="/board" element={wrappedPage(<Board user={user} />, "tasker")}>
                  <Route path=":orgName" element={wrappedPage(<Board user={user} />, "tasker")} />
                  <Route path=":orgName/:board" element={wrappedPage(<Board user={user} />, "tasker")} />
                  <Route path=":orgName/:board/:taskname" element={wrappedPage(<Board user={user} />, "tasker")} />
                  <Route path=":orgName/:board/:taskname/:task" element={wrappedPage(<Board user={user} />, "tasker")} />
                  <Route element={wrappedPage(<Landing />, "tasker")} />
                </Route>

                <Route path="/qms">
                  <Route index={true} element={<Qms />} />
                  <Route index={false} path="/qms/page" element={<div>page</div>} />
                </Route>

                <Route path="/lrs">
                  <Route index={true} element={wrappedPage(<LrsHome user={user} currentOrg={currentOrg} />, "lrs")} />
                  <Route
                    index={false}
                    path="/lrs/table"
                    element={wrappedPage(<LrsDash isDesktop={isDesktop} user={user} currentOrg={currentOrg} />, "lrs")}
                  />
                </Route>

                <Route path="/iot">
                  <Route index={true} element={wrappedPage(<DashboardApp user={user} currentOrg={currentOrg} />, "iot")} />
                  <Route index={false} path="/iot/user" element={wrappedPage(<User />, "iot")} />
                  <Route index={false} path="/iot/products" element={wrappedPage(<Products />, "iot")} />
                </Route>

                <Route path="/room" element={wrappedPage(<Room user={user} currentOrg={currentOrg} currentRoom={currentRoom} />, "tasker")}>
                  <Route path=":id" element={wrappedPage(<Room user={user} currentOrg={currentOrg} currentRoom={currentRoom} />, "tasker")} />
                </Route>

                <Route path="/room/lrs" element={wrappedPage(<Room user={user} currentOrg={currentOrg} currentRoom={currentRoom} />, "lrs")}>
                  <Route path=":id" element={wrappedPage(<Room user={user} currentOrg={currentOrg} currentRoom={currentRoom} />, "lrs")} />
                </Route>

                <Route path="*" element={<NotFound />} />
              </Routes>
              {currentOrg && currentRoom && <RoomModal currentOrg={currentOrg} currentRoom={currentRoom} user={user} />}
            </>
          ) : (
            <Routes>
              <Route path="/" element={<AuthenticationMain />} />
              <Route path="/reset" element={<Reset />} />
              <Route path="*" element={<AuthenticationMain />} />
            </Routes>
          )}
        </Suspense>
      </SnackbarProvider>
    </ThemeConfig>
  );
}
