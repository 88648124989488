/* eslint-disable import/no-anonymous-default-export */
// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

// const setView = (view) => {
//   if (landing) {
//     navigate(`/board/${orgName}?view=${view}`);
//   } else {
//     navigate(`/board/${orgName}/${boradName}?view=${view}`);
//   }
// };

// const setPath = () => {
//   if (landing) {
//     return `/board/${orgName}`;
//   } else {
//     return `/board/${orgName}/${boradName}`;
//   }
// };

const iot = [
  {
    title: "dashboard",
    path: "/iot",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "user",
    path: "/iot/user",
    icon: getIcon("eva:people-fill"),
  },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill')
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill')
  // },
  // {
  //   title: "login",
  //   path: "/login",
  //   icon: getIcon("eva:lock-fill"),
  // },
  // {
  //   title: "register",
  //   path: "/register",
  //   icon: getIcon("eva:person-add-fill"),
  // },
  {
    title: "Not found",
    path: "/404",
    icon: getIcon("eva:alert-triangle-fill"),
  },
];

const lrs = [
  {
    title: "home",
    path: "/lrs",
    matchPath: "/lrs",
    icon: getIcon("mdi-light:home"),
  },
  {
    title: "table",
    path: "/lrs/table",
    matchPath: "/table",
    icon: getIcon("carbon:table-built"),
  },
  {
    title: "scheduler",
    path: "/lrs/table?view=s",
    matchPath: "/s",
    icon: getIcon("uis:calender"),
  },
  { title: "Chat room", path: "/room/lrs?l=true", icon: getIcon("bxs:chat") },
];

const tasker = [
  {
    title: "home",
    path: "/tasker",
    icon: getIcon("codicon:home"),
  },
  {
    title: "organisation",
    path: "/org",
    icon: getIcon("codicon:organization"),
  },
  {
    title: "board",
    path: "/board",
    view: "l",
    icon: getIcon("fluent:board-16-filled"),
  },
  {
    title: "table",
    path: "/board",
    view: "t",
    icon: getIcon("carbon:table-built"),
  },
  {
    title: "scheduler",
    path: "/board",
    view: "s",
    icon: getIcon("uis:calender"),
  },
  {
    title: "archive",
    path: "/board",
    view: "a",
    icon: getIcon("bxs:archive"),
  },
  { title: "Chat room", path: "/room", icon: getIcon("bxs:chat") },
];

const noOrg = [
  {
    title: "home",
    path: "/",
    icon: getIcon("mdi-light:home"),
  },
  {
    title: "organisation",
    path: "/org",
    icon: getIcon("eva:file-text-fill"),
  },
];

export default {
  tasker,
  iot,
  lrs,
  noOrg,
};
