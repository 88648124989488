import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import shallow from "zustand/shallow";
import { useUserStore } from "../store";
import logo from "../assets/images/INCA-small.png";

// material
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  // const [logo] = useUserStore((state) => [state.logo], shallow);

  return (
    <RouterLink to="/tasker">
      <Box
        component="img"
        alt="logo"
        src={"https://fletchbiz.co.uk/wp-content/uploads/2021/12/logo-1-min.png"}
        sx={{ width: 110, height: 60, background: "transparent", ml: 1, ...sx }}
      />
    </RouterLink>
  );
}
